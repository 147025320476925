import {DynamicEntityKey, EntityParsed, StaticEntityKey} from './entity.interface';

export interface StepData {
  [key: string]: EntityParsed;
}

export interface Step {
  id: StepId;
  hasNext: boolean;
}

export enum MacroStepId {
  Initialization = 'init',
  Process = 'process',
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
  OldBrowser = 'old-browser',
  NotFound = 'not-found',
  Recognition = 'attiva'
}

export enum StepId {
  Activation = 'activation',
  ActivationOtp = 'activation-otp',
  Address = 'address',
  Coupon = 'coupon',
  Consents = 'consents',
  Contacts = 'contacts',
  Customer = 'customer',
  Document = 'document',
  FDOIccid = 'fdo-iccid',
  NextSteps = 'next-steps',
  OfferExtras = 'offer-extras',
  Payment = 'payment',
  PaymentMethod = 'payment-method',
  Shipping = 'shipping',
  Sim = 'sim-type',
  SimCheck = 'sim-check',
  Success = 'success',
  Summary = 'summary',
  Winback = 'winback'
}

export const ProcessStepsIds = [
  StepId.Activation,
  StepId.ActivationOtp,
  StepId.Address,
  StepId.Coupon,
  StepId.Consents,
  StepId.Contacts,
  StepId.Customer,
  StepId.Document,
  StepId.FDOIccid,
  StepId.OfferExtras,
  StepId.Payment,
  StepId.PaymentMethod,
  StepId.Shipping,
  StepId.Sim,
  StepId.Summary,
  StepId.Winback
];

export const StepRequiredEntitiesKeysMap = {
  [StepId.FDOIccid]: [
    StaticEntityKey.FunnelInfo,
    DynamicEntityKey.FdoIccid
  ],
  [StepId.Coupon]: [
    StaticEntityKey.FunnelInfo,
    DynamicEntityKey.CouponInfo
  ],
  [StepId.Winback]: [
    StaticEntityKey.FunnelInfo,
    DynamicEntityKey.WinbackInfo
  ],
  [StepId.Sim]: [
    StaticEntityKey.FunnelInfo,
    DynamicEntityKey.SimType
  ],
  [StepId.Shipping]: [
    StaticEntityKey.AvailableShippingMethods,
    DynamicEntityKey.ShippingMethod
  ],
  [StepId.Customer]: [
    StaticEntityKey.FunnelInfo,
    DynamicEntityKey.CustomerIdentity,
    DynamicEntityKey.GeneralConsents,
    DynamicEntityKey.SimType,
    StaticEntityKey.Offer,
    StaticEntityKey.OfferAlternative,
    DynamicEntityKey.OfferExtras
  ],
  [StepId.Consents]: [
    DynamicEntityKey.MarketingConsents,
    DynamicEntityKey.SimType,
    StaticEntityKey.Offer,
    StaticEntityKey.OfferAlternative,
    DynamicEntityKey.OfferExtras
  ],
  [StepId.Contacts]: [
    DynamicEntityKey.GeneralConsents,
    DynamicEntityKey.CustomerContacts,
    DynamicEntityKey.SimType
  ],
  [StepId.Document]: [
    StaticEntityKey.CustomerAvailableDocuments,
    DynamicEntityKey.CustomerDocument
  ],
  [StepId.Activation]: [
    StaticEntityKey.AvailableActivationMethods,
    StaticEntityKey.AvailableOperators,
    DynamicEntityKey.ActivationMethod,
    DynamicEntityKey.ActivationMnpInfo
  ],
  [StepId.ActivationOtp]: [
    StaticEntityKey.AvailableOperators,
    DynamicEntityKey.ActivationMnpInfo,
    DynamicEntityKey.OtpInfo,
  ],
  [StepId.Address]: [
    DynamicEntityKey.CustomerIdentity,
    StaticEntityKey.AvailableCountries,
    DynamicEntityKey.CustomerDeliveryAddress,
    DynamicEntityKey.CustomerResidenceAddress,
    DynamicEntityKey.SimType
  ],
  [StepId.PaymentMethod]: [
    StaticEntityKey.FunnelInfo,
    StaticEntityKey.PaymentAvailableMethods,
    StaticEntityKey.PaymentPaypalConfiguration,
    StaticEntityKey.PaymentSIAConfiguration,
    DynamicEntityKey.MgmInfo,
    DynamicEntityKey.CouponInfo,
    DynamicEntityKey.ActivationMethod,
    DynamicEntityKey.PaymentMethod,
    DynamicEntityKey.PaypalTransactionInfo,
    DynamicEntityKey.OfferExtras
  ],
  [StepId.Payment]: [
    DynamicEntityKey.PaymentMethod,
    DynamicEntityKey.PaypalTransactionInfo
  ],
  [StepId.OfferExtras]: [
    StaticEntityKey.Offer,
    StaticEntityKey.OfferAlternative,
    StaticEntityKey.FunnelInfo,
    StaticEntityKey.AvailableAddons,
    StaticEntityKey.SmartTopUpPromo,
    DynamicEntityKey.MgmInfo,
    DynamicEntityKey.CouponInfo,
    DynamicEntityKey.ActivationMethod,
    DynamicEntityKey.OfferExtras,
    DynamicEntityKey.SelectedAddons,
    StaticEntityKey.AddonsConfig
  ],
  [StepId.Summary]: [
    StaticEntityKey.OrderSummary,
    StaticEntityKey.FunnelInfo,
    DynamicEntityKey.CustomerContacts,
    DynamicEntityKey.PaymentMethod,
    DynamicEntityKey.ActivationMethod,
    DynamicEntityKey.ActivationMnpInfo,
    DynamicEntityKey.PaypalTransactionInfo,
    DynamicEntityKey.SimType,
    DynamicEntityKey.OfferExtras,
    DynamicEntityKey.MgmInfo,
    DynamicEntityKey.CouponInfo,
    DynamicEntityKey.ActivationMethod
  ],
  [StepId.Success]: [
    DynamicEntityKey.OfferExtras,
    StaticEntityKey.OrderSummary,
    DynamicEntityKey.CustomerContacts,
    DynamicEntityKey.PaymentMethod,
    DynamicEntityKey.ActivationMethod,
    DynamicEntityKey.ActivationMnpInfo,
    DynamicEntityKey.PaypalTransactionInfo,
    DynamicEntityKey.SimType
  ]
};
