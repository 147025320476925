<ng-container *ngIf="activeModal">
  <wt-modal
    [showCloseButton]="activeModal.closeButton"
    [isOpen]="!!activeModal"
    [additionalClasses]="activeModal.additionalClasses"
    (close)="activeModal.close()"
  >
    <ng-container [ngSwitch]="activeModal.type">
      <ng-container *ngSwitchCase="ModalType.Error">
        <div class="sb-modal__title wt-margin-top-4" *ngIf="activeModal.title">
          {{activeModal.title}}
        </div>
        <div class="sb-modal__section wt-margin-top-4">
          <div class="sb-modal__section__content">
            <span class="sb-modal__text" [innerHTML]="activeModal.content | translate"></span>
          </div>
        </div>
        <div class="row center-xs sb-actions wt-margin-top-4">
          <div class="col-xs-12 col-md-12 sb-actions__modal">
            <wt-button wt-primary (click)="activeModal.close()">
              {{'MODALS.ICCID-ERROR.ACTION' | translate}}
            </wt-button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="ModalType.PreviousStep">
        <div class="sb-modal__title wt-margin-top-4">
          {{'MODALS.CONFIRM.TITLE' | translate}}
        </div>
        <div class="sb-modal__section">
          <div class="sb-modal__section__content">
            <span class="sb-modal__text" [innerHTML]="activeModal.content | translate"></span>
          </div>
        </div>
        <div class="row sb-actions wt-margin-top-4">
          <div class="col-xs-12 col-sm-6 sb-actions__modal">
            <wt-button wt-secondary (click)="activeModal.resolve(true)">
              {{'MODALS.CONFIRM.ACTION.STEP_CANCEL' | translate}}
            </wt-button>
          </div>
          <div class="col-xs-12 col-sm-6 sb-actions__modal">
            <wt-button wt-primary (click)="activeModal.resolve(false)">
              {{'MODALS.CONFIRM.ACTION.STEP_BACK' | translate}}
            </wt-button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="ModalType.Confirmation">
        <div class="sb-modal__section">
          <div class="sb-modal__section__content">
            <p class="sb-modal__title confirmation" *ngIf="activeModal.title" [innerHTML]="activeModal.title | translate"></p>
            <span class="sb-modal__text" [innerHTML]="activeModal.content | translate"></span>
          </div>
        </div>
        <div class="row sb-actions wt-margin-top-4" [ngClass]="{
          'vertical': activeModal.buttonsPosition === ModalButtonPositionEnum.VERTICAL,
          'vertical reverse': activeModal.buttonsPosition === ModalButtonPositionEnum.VERTICAL_REVERSE}">
          <div class="col-xs-12 col-sm-6 sb-actions__modal" *ngIf="!activeModal.hideCancelButton" [ngClass]="activeModal.buttonContainerClass ? activeModal.buttonContainerClass : 'not-set'">
            <wt-button wt-secondary (click)="activeModal.resolve(false)">
              {{(activeModal.options?.cancelTranslationKey || 'MODALS.CONFIRM.ACTION.CANCEL') | translate}}
            </wt-button>
          </div>
          <div class="col-xs-12 col-sm-6 sb-actions__modal" [ngClass]="activeModal.buttonContainerClass ? activeModal.buttonContainerClass : 'not-set'">
            <wt-button wt-primary (click)="activeModal.resolve(true)" >
              {{(activeModal.options?.confirmTranslationKey || 'MODALS.CONFIRM.ACTION.CONFIRM') | translate}}
            </wt-button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="ModalType.Coupon">
        <!--OLD CODE <div class="sb-modal__title wt-margin-top-4">
         OLD CODE  {{'MODALS.MGM_INFO.TITLE' | translate}}
        OLD CODE </div>-->
        <div class="sb-modal__section">
          <div class="sb-modal__section__content">
            <div class="center-xs wt-margin-bottom-2"><img src="assets/images/promo-coupon.svg" alt="Promo Coupon" style="height:75px"></div>
            <div class="sb-modal__text wt-text-align-center" [innerHTML]="(activeModal.content || 'COUPON.INSERT_CODE') | translate"></div>
          </div>
          <div class="sb-modal__section__content wt-margin-bottom-4 sb-special-code-input-box" [formGroup]="modalFormGroup">
            <wt-input-text additionalClass="sb-transform-uppercase sb-special-code-input"
                 [placeholder]="'COUPON.CODE_PLACEHOLDER' | translate"
                 [maxLength]="10" [formControlName]="'couponCode'"></wt-input-text>
            <div class="wt-text-base-s input__error" style="white-space: normal !important;" *ngIf="modalError" [innerHTML]="'COUPON.CODE_ERROR.' + modalError | translate">
            </div>
          </div>
        </div>
        <div class="row sb-actions">
          <div class="col-xs-12 col-md-12 sb-actions__modal">
            <wt-button wt-primary [disabled]="isCOUPONButtonDisabled" (click)="activeModal.resolve()">
              {{'COUPON.SEND_CODE_ACTION' | translate}}
            </wt-button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="ModalType.VRError">
        <div class="sb-modal__title wt-margin-top-4">
          {{'MODALS.VR_ERROR.TITLE' | translate}}
        </div>
        <div class="sb-modal__section">
          <div class="sb-modal__section__content">
            <span class="sb-modal__text" [innerHTML]="(activeModal.content || 'MODALS.VR_ERROR.TEXT') | translate"></span>
          </div>
        </div>
        <div class="row sb-actions">
          <div class="col-xs-12 col-md-12 sb-actions__modal">
            <wt-button wt-primary (click)="activeModal.close()">
              {{'MODALS.VR_ERROR.ACTION' | translate}}
            </wt-button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="ModalType.MgmInfo">
        <!--OLD CODE <div class="sb-modal__title wt-margin-top-4">
          OLD CODE {{'MODALS.MGM_INFO.TITLE' | translate}}
        OLD CODE </div>-->
        <div class="sb-modal__section">
          <div class="sb-modal__section__content">
            <div class="center-xs wt-margin-bottom-2"><img src="assets/images/gift-small.svg" alt="Gift" style="height:75px"></div>
            <span class="sb-modal__text" [innerHTML]="(activeModal.content || 'MGM.INSERT_CODE') | translate"></span>
          </div>
          <div class="sb-modal__section__content wt-margin-bottom-4 sb-special-code-input-box" [formGroup]="modalFormGroup">
            <wt-input-text additionalClass="sb-transform-uppercase sb-special-code-input"
                 [placeholder]="'MGM.CODE_PLACEHOLDER' | translate"
                 [validateOnBlur]="true"
                 [maxLength]="8" [formControlName]="'mgmCode'"></wt-input-text>
            <div class="wt-text-base-s input__error" style="white-space: normal !important;" *ngIf="modalError" [innerHTML]="'MGM.CODE_ERROR.' + modalError | translate">
            </div>
          </div>
        </div>
        <div class="row sb-actions">
          <div class="col-xs-12 col-md-12 sb-actions__modal">
            <wt-button wt-primary [disabled]="isMGMButtonDisabled" (click)="activeModal.resolve()">
              {{'MGM.SEND_CODE_ACTION' | translate}}
            </wt-button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="ModalType.OperatorPriceChange">
        <div class="sb-modal__title wt-margin-top-4">
          {{'MODALS.OPERATOR_PRICE_CHANGE.MESSAGE_PART1' | translate}}
          {{activeModal.options.newPrice | currency:activeModal.options.currency | inferCurrencyLabel}}
          {{'MODALS.OPERATOR_PRICE_CHANGE.MESSAGE_PART2' | translate}}
          {{activeModal.options.oldPrice | currency:activeModal.options.currency | inferCurrencyLabel}}.
        </div>
        <div class="sb-modal__section wt-margin-top-4">
          <div class="sb-modal__section__content">
            <span class="sb-modal__text" [innerHTML]="'MODALS.OPERATOR_PRICE_CHANGE.SUBTEXT' | translate"></span>
          </div>
        </div>
        <div class="row center-xs sb-actions wt-margin-top-4">
          <div class="col-xs-12 col-sm-12 sb-actions__modal">
            <wt-button wt-primary (click)="activeModal.resolve(true)">
              {{'MODALS.OPERATOR_PRICE_CHANGE.BUTTON' | translate}}
            </wt-button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="ModalType.IccidError">
        <div class="sb-modal__title wt-margin-top-4">
          {{'MODALS.ICCID-ERROR.TITLE' | translate}}
        </div>
        <div class="sb-modal__section wt-margin-top-4">
          <div class="sb-modal__section__content">
            <span class="sb-modal__text" [innerHTML]="'MODALS.ICCID-ERROR.TEXT' | translate"></span>
          </div>
        </div>
        <div class="row center-xs sb-actions wt-margin-top-4">
          <div class="col-xs-12 col-md-12 sb-actions__modal">
            <wt-button wt-primary (click)="activeModal.close()">
              {{'MODALS.ICCID-ERROR.ACTION' | translate}}
            </wt-button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="ModalType.H3GError">
        <div class="sb-modal__title wt-margin-top-4">
          {{'MODALS.H3G-ERROR.TITLE' | translate}}
        </div>
        <div class="sb-modal__section wt-margin-top-4">
          <div class="sb-modal__section__content">
            <span class="sb-modal__text" [innerHTML]="'MODALS.H3G-ERROR.TEXT' | translate"></span>
          </div>
        </div>
        <div class="row center-xs sb-actions wt-margin-top-4">
          <div class="col-xs-12 col-md-12 sb-actions__modal">
            <wt-button wt-primary (click)="activeModal.resolve()">
              {{'MODALS.H3G-ERROR.ACTION' | translate}}
            </wt-button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="ModalType.VRTutorial">
        <iframe width="560" height="315"
                title="VR Tutorial"
                src="https://www.youtube.com/embed/faXBm-PnHDQ?rel=0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </ng-container>
      <ng-container *ngSwitchCase="ModalType.FlowError">
        <div class="sb-modal__title">
          {{'MODALS.CONFIRM.TITLE' | translate}}
        </div>
        <div class="sb-modal__section wt-margin-top-4">
          <div class="sb-modal__section__content">
            <span class="sb-modal__text" [innerHTML]="'MODALS.ERROR.TEMPORARY' | translate"></span>
          </div>
        </div>
        <div class="row center-xs sb-actions wt-margin-top-4">
          <div class="col-xs-12 col-md-12 sb-actions__modal">
            <wt-button wt-primary (click)="activeModal.close()">
              {{'MODALS.FLOW-ERROR.ACTION' | translate}}
            </wt-button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="ModalType.PromoInfo">
        <div class="sb-modal__title wt-text-align-center">
          <img src="/assets/images/promo-discount.svg" title="Promo" alt="Promo discount" class="sb-box-info__icon">
        </div>
        <div class="sb-modal__section wt-margin-top-4">
          <div class="sb-modal__section__content wt-text-align-center">
            <span class="sb-modal__text wt-text-align-center" [innerHTML]="activeModal.content | translate"></span>
          </div>
        </div>
        <div class="row center-xs sb-actions wt-margin-top-4">
          <div class="col-xs-12 col-md-12 sb-actions__modal">
            <wt-button wt-primary (click)="activeModal.close()">
              {{'MODALS.CONFIRM.ACTION.UNDERSTOOD' | translate}}
            </wt-button>
          </div>
        </div>
      </ng-container>
      <!-- region TaxCodeCalculation Modal -->
      <ng-container *ngSwitchCase="ModalType.TaxCodeCalculation">
        <div class="sb-modal__title">
          {{'MODALS.TAX-CODE-CALCULATION.TITLE' | translate}}
        </div>
        <div class="sb-modal__section" style="display: none;">
          <div class="sb-modal__section__content">
            <span class="sb-modal__text" [innerHTML]="'MODALS.TAX-CODE-CALCULATION.SUBTITLE' | translate"></span>
          </div>
        </div>
        <div [formGroup]="fiscalCodeFormGroup" >
          <div class="row wt-margin-top-4">
            <div class="col-xs-12 col-sm-12">
              <wt-input-text lastPassHandler
                [label]="'ENTITY.CUSTOMER-IDENTITY.NAME' | translate"
                [maxLength]="FormConstants.MAXIMUM_NAME_LENGTH"
                formControlName="firstName" #formField></wt-input-text>
            </div>
            <div class="col-xs-12 col-sm-12">
              <wt-input-text lastPassHandler
                [label]="'ENTITY.CUSTOMER-IDENTITY.SURNAME' | translate"
                [maxLength]="FormConstants.MAXIMUM_NAME_LENGTH"
                formControlName="lastName"></wt-input-text>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-6 col-sm-6">
              <wt-input-date lastPassHandler [validateOnBlur]="true"
                             [label]="'ENTITY.CUSTOMER-IDENTITY.BIRTH-DATE' | translate"
                             [placeholder]="'ENTITY.CUSTOMER-DOCUMENT.RELEASE-DATE.PLACEHOLDER' | translate"
                             formControlName="birthDate"></wt-input-date>
            </div>
            <div class="col-xs-6 col-sm-6">
              <wt-input-select [label]="'ENTITY.CUSTOMER-IDENTITY.SEX' | translate"
                               [placeholder]="'ENTITY.CUSTOMER-IDENTITY.SEX' | translate"
                               [items]="genderTypes" formControlName="gender"></wt-input-select>
            </div>
          </div>
          <div class="row wt-margin-bottom-4">
            <div class="col-xs-6 col-sm-6">
              <wt-input-select-autocomplete
                [items]="availableCountries"
                bindLabel="name"
                bindValue="name"
                [label]="'ENTITY.ADDRESS.NATIONALITY' | translate"
                [notFoundText]="'ENTITY.ADDRESS.COUNTRY.NOT-FOUND' | translate"
                formControlName="birthNation"></wt-input-select-autocomplete>
            </div>
            <div class="col-xs-6 col-sm-6" *ngIf="!isBirthCityDisabled">
              <wt-input-select-autocomplete
                formControlName="birthCity"
                bindLabel="fullName"
                bindValue="city"
                [disabled]="isBirthCityDisabled"
                [notFoundText]="'ENTITY.ADDRESS.CITY.PLACEHOLDER' | translate"
                [label]="'ENTITY.CUSTOMER-IDENTITY.BIRTH-TOWN' | translate"
                [loading]="cityAutocompleteInputLoading"
                [items]="cityAutocompleteInputOptions"
                (search)="searchCitiesOnUserInputSubject.next($event)"
                (change)="fillDistrictFromCityModel($event)"
              ></wt-input-select-autocomplete>
            </div>
          </div>
        </div>
        <div class="row wt-margin-bottom-4 error-taxcode" *ngIf="modalError">
          <div class="">
            <img class="error-taxcode-icon" src="/assets/images/icon-alert-exlamation-red.svg" alt="alert icon" >
          </div>
          <div class="error-taxcode-text">
            {{'MODALS.TAXCODE.ERROR.TEXT' | translate}}
          </div>
        </div>
        <div class="sb-modal__notaxcode" [innerHTML]="'MODALS.TAX-CODE-CALCULATION.SUBTITLE-NO-TAXCODE' | translate">
        </div>
        <div class="row sb-actions">
          <div class="col-xs-6 col-md-6">
            <wt-button wt-secondary wt-round [cssClasses]="['wt-button--inverse', 'wt-button--inverse--noborder']" (click)="activeModal.close()">
              {{'MODALS.CONFIRM.ACTION.EXIT' | translate}}
            </wt-button>
          </div>
          <div class="col-xs-6 col-md-6">
            <wt-button wt-primary wt-round [disabled]="this.fiscalCodeFormGroup.invalid" (click)="activeModal.resolve()">
              {{'MODALS.TAX-CODE-CALCULATION.ACTION' | translate}}
            </wt-button>
          </div>
        </div>
      </ng-container>
      <!-- endregion -->
      <ng-container *ngSwitchCase="ModalType.Generic">
        <div class="sb-modal__title wt-margin-top-4" *ngIf="activeModal.title">
          {{activeModal.title | translate}}
        </div>
        <div class="sb-modal__section">
          <div class="sb-modal__section__content">
            <span class="sb-modal__text" [innerHTML]="activeModal.content | translate"></span>
          </div>
        </div>
      </ng-container>
      <!-- region Otp Modal -->
      <ng-container *ngSwitchCase="ModalType.OtpModal">
        <div class="wt-text-base-s wt-margin-bottom-2"
                 style="white-space: normal !important;"
                 *ngIf="activeModal.options && activeModal.options.notification">
          <div class="sb-modal__notifications">
            <div class="icon">
              <img src="/assets/images/icon-success.svg" class="notifications__icon" alt="icona" >
            </div>
            <div class="text">
              {{'ENTITY.ACTIVATION-MNP-INFO.NOTIFICATION' | translate}}
            </div>
          </div>
        </div>
        <div class="sb-modal__title">
          {{activeModal.title | translate}}
        </div>
        <div class="sb-modal__section">
          <div class="sb-modal__section__content wt-margin-bottom-2">
            <div class="sb-modal__text"
                 [innerHTML]="'ENTITY.ACTIVATION-MNP-INFO.MODAL_OTP_CONTENT' | translate:{ info: activeModal.options && activeModal.options.number }">
            </div>
          </div>
          <div class="sb-modal__section__content wt-margin-bottom-2" [formGroup]="modalFormGroup">
            <div class="input-complex">
              <wt-input-text additionalClass="sb-transform-uppercase st-otp-input"
                           [label]="'ENTITY.ACTIVATION-MNP-INFO.MODAL_OTP_FIELD' | translate"
                           [type]="'numeric'"
                           [maxLength]="6"
                             [formControlName]="'otpCode'">
                          </wt-input-text>
                          <wt-info-note [showCloseButton]="true" [invert]="true" width="615px" class="flex__info-note" text="{{'ENTITY.ACTIVATION-MNP-INFO.MODAL_OTP_TOOLTIP' | translate}}"></wt-info-note>
            </div>
            <div class="wt-text-base-s input__error st-otp-input-error"
                 style="white-space: normal !important;"
                 *ngIf="modalError" [innerHTML]="inferOtpErrorText(modalError) | translate">
            </div>
          </div>
          <div class="row wt-margin-bottom-4">
            <div class="col-xs-12">
              <div class="wt-text-base-m"><a class="wt-link" (click)="activeModal.resendOtp(false);">{{'ENTITY.ACTIVATION-MNP-INFO.OTP_NO_CODE' | translate}}</a></div>
            </div>
          </div>
          <div class=" row wt-margin-bottom-4">
            <div class="col-xs-12 wt-margin-bottom-4">
              <sb-box-info [transparent]="true" iconType="icon-alert-info-green" [infoText]="'ENTITY.ACTIVATION-MNP-INFO.INFO_BOX' | translate:{ otpMaxSendRetry: activeModal.options && activeModal.options.otpMaxSendRetry, otpValidityMin: activeModal.options && activeModal.options.otpValidityMin, maxSendFrequency: activeModal.options && activeModal.options.sendFrequency}"></sb-box-info>
            </div>
          </div>
        </div>
        <div class="row sb-actions">
          <div class="col-xs-12">
            <wt-button wt-primary wt-round [disabled]="isOtpButtonDisabled" (click)="activeModal.resolve()">
              {{'MODALS.CONFIRM.ACTION.VERIFY' | translate}}
            </wt-button>
          </div>
        </div>
      </ng-container>
      <!-- endregion -->
       <!-- region ESIM Compatibile Devices Modal -->
      <ng-container *ngSwitchCase="ModalType.EsimCompatibleDevices">
        <iframe class="esim-modal-box" src="https://verymobile.it/test-pages/quali-smartphone-sono-compatibili-con-le-esim-very" frameborder="0" title="Dispositivi compatibili con eSIM"></iframe>
      </ng-container>
    </ng-container>
  </wt-modal>
</ng-container>
