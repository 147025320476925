import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { BasketData, Cart, Offer, StepId, TotalPrice, ValidationError } from 'models';
import { ModalType } from '../../../app-shared/models/modal.interface';
import { RootState } from '../../store';
import { ProcessFeatureActions, ProcessFeatureSelectors } from '../../store/process-feature-store';
import {openEsimCompatibleDevicesModal} from '../../store/process-feature-store/actions/modal.actions';
import { RouterSelectors } from '../../store/route-feature-store';

@Injectable()
export class ProcessFeatureStoreFacadeService {
  private validationErrorsSubject = new Subject<ValidationError>();

  constructor(
    public store$: Store<RootState.RootState>,
    private translateService: TranslateService
  ) { }

  get route() {
    return {
      domain$: ((): Observable<string> => this.store$.select(RouterSelectors.selectUrl))(),
      url$: ((): Observable<string> => this.store$.select(RouterSelectors.selectUrl))(),
      componentUrlPath$: ((): Observable<string> => this.store$.select(RouterSelectors.selectComponentUrlPath))(),
      queryParams$: ((): Observable<Params> => this.store$.select(RouterSelectors.selectQueryParams))(),
      pathParams$: ((): Observable<Params> => this.store$.select(RouterSelectors.selectParams))()
    };
  }

  get header() {
    return {
      setToken: (token: string) => this.store$.dispatch(ProcessFeatureActions.HeaderActions.setToken({ token }))
    };
  }

  get flow() {
    return {
      getStepData: (stepId?: StepId) => this.store$.dispatch(ProcessFeatureActions.FlowActions.getStepData({ stepId })),
      postStepData: (data: BasketData, stepId: StepId) =>
        this.store$.dispatch(ProcessFeatureActions.FlowActions.postStepData({ data, stepId }))
    };
  }

  get cart() {
    return {
      cart$: ((): Observable<Cart> => this.store$.select(ProcessFeatureSelectors.CartSelectors.selectCart))(),
      offer$: ((): Observable<Offer> => this.store$.select(ProcessFeatureSelectors.OfferSelectors.selectOffer))(),
      totalPrices$: ((): Observable<TotalPrice[]> => this.store$.select(ProcessFeatureSelectors.CartSelectors.selectTotalPrices))()
    };
  }

  get translation() {
    return {
      getCurrentLang: () => this.translateService.currentLang,
      setTranslation: (lang: string, translations: any, shouldMerge: boolean = true) =>
        this.store$.dispatch((ProcessFeatureActions.TranslationActions.setTranslations({ lang, translations, shouldMerge })))
    };
  }

  get spinner() {
    return {
      updateSpinnerStatus: (isActive: boolean) =>
        this.store$.dispatch((ProcessFeatureActions.SpinnerActions.updateSpinnerStatus({ status: isActive })))
    };
  }

  get modals() {
    return {
      openModal: (modalType: ModalType, params?: any) =>
        this.store$.dispatch(ProcessFeatureActions.ModalActions.openModal({
          modalType,
          params
        })),
      openVRTutorialModal: () =>
        this.store$.dispatch(ProcessFeatureActions.ModalActions.openVrTutorialModal({})),
      openEsimCompatibleDevicesModal: () =>
        this.store$.dispatch(ProcessFeatureActions.ModalActions.openEsimCompatibleDevicesModal({})),
    };
  }

  get errors() {
    const validationErrorsObservable$ = this.validationErrorsSubject.asObservable();
    const isInputError = (validationError: ValidationError) => Boolean(validationError.path && validationError.path.includes('.'));
    const isCrossError = (validationError: ValidationError) => !isInputError(validationError);

    return {
      validationErrors$: validationErrorsObservable$,
      inputValidationErrors$: (() => validationErrorsObservable$.pipe(filter(isInputError)))(),
      crossValidationErrors$: (() => validationErrorsObservable$.pipe(filter(isCrossError)))(),

      addValidationErrors: (validationErrors: ValidationError[]) =>
        validationErrors.forEach(validationError => this.validationErrorsSubject.next(validationError)),
      openErrorModal: (message: string, icon?: string, title?: string) =>
        this.store$.dispatch(ProcessFeatureActions.ModalActions.openGenericErrorModal({ payload: message, icon, title })),
      /*openRedirectModal: (message: string, url: string) =>
        this.store$.dispatch(ProcessFeatureActions.ModalActions.openRedirectModal({ message, url })),*/
      openConfirmModal: (message: string, resolve: any) =>
        this.store$.dispatch(ProcessFeatureActions.ModalActions.openPreviousStepModal({ message, resolve })),
      openIccidErrorModal: () =>
        this.store$.dispatch(ProcessFeatureActions.ModalActions.openIccidErrorModal({})),
      openH3GErrorModal: () =>
        this.store$.dispatch(ProcessFeatureActions.ModalActions.openH3GErrorModal({})),
      openOperatorPriceChangeModal: (options: any, resolve: any) =>
        this.store$.dispatch(ProcessFeatureActions.ModalActions.openOperatorPriceChangeModal({ options, resolve })),
      openVRErrorModal: (message: string) =>
        this.store$.dispatch(ProcessFeatureActions.ModalActions.openVrErrorModal({ message }))
    };
  }
}
