import { Component, HostListener, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';

import { City, DynamicEntityKey, GenderType, StaticEntityKey } from 'models';
import { combineLatest, Subject, Subscription} from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, first, tap } from 'rxjs/operators';
import { AnalyticsService, BasketService, ExecService, ValidatorsService } from 'services';
import { InputTextComponent } from 'ui-kit-lib/lib/components/input-text/input-text.component';

import {Modal, ModalButtonPositionEnum, ModalType} from '../../../app-shared/models/modal.interface';
import * as FormConstants from '../../../app/modules/shared/constants/form.constants';
import { AppStoreFacadeService } from '../../facades/app-store-facade.service';
import {AVAILABLE_COUNTRIES} from '../../modules/process/constants/countries.constants';
import { MAXIMUM_OTP_LENGTH } from '../../modules/shared/constants/form.constants';
import * as ModalActions from '../../store/process-feature-store/actions/modal.actions';


const DEFAULT_COUNTRY = 'ITALIA';
const DEFAULT_CITY = 'EE';
const DEFAULT_DISTRICT = 'EE';

@Component({
  selector: 'sb-modals',
  templateUrl: './modals.component.html'
})
export class ModalsComponent implements OnInit, OnDestroy {
  private static USER_INPUT_DEBOUNCE_INTERVAL = 200;
  public ModalType = ModalType;
  public activeModal: Modal = null;
  public fiscalCodeFormGroup: FormGroup;
  private subscriptions = new Subscription();
  public ModalButtonPositionEnum = ModalButtonPositionEnum;

  public FormConstants = FormConstants;
  public genderTypes: GenderType[] = [
    { id: 'M', name: 'Uomo' },
    { id: 'F', name: 'Donna' }
  ];
  public availableCountries = AVAILABLE_COUNTRIES;
  public cityAutocompleteInputOptions: City[] = [];
  public searchCitiesOnUserInputSubject = new Subject();
  public cityAutocompleteInputLoading = false;

  public modalFormGroup: FormGroup;
  modalError: string | boolean;

  @ViewChildren('formField') field: QueryList<InputTextComponent>;
  @HostListener('document:keydown', ['$event'])
  public handleKeydown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.activeModal = null;
    }
  }

  constructor(
    private actions$: Actions,
    private fb: FormBuilder,
    private execService: ExecService,
    private analyticsService: AnalyticsService,
    private appStoreFacadeService: AppStoreFacadeService,
    private basketService: BasketService
  ) {}

  generateModalFormGroup() {
    this.modalFormGroup = this.fb.group({
      mgmCode: this.fb.control(undefined,  Validators.compose([
        ValidatorsService.size(8, 8),
        ValidatorsService.textual({ alphabetic: true, digits: true })
      ])),
      couponCode: this.fb.control(undefined, Validators.maxLength(10)),
      otpCode: this.fb.control(undefined, ValidatorsService.size(6, 6)),
    });
  }

  generateFiscalCodeFormGroup() {
    this.fiscalCodeFormGroup = this.fb.group({
      firstName: this.fb.control(undefined, Validators.required),
      lastName: this.fb.control(undefined, Validators.required),
      birthDate: this.fb.control(undefined, Validators.required),
      gender: this.fb.control(undefined, Validators.required),
      birthCity: this.fb.control(undefined, Validators.required),
      birthDistrict: this.fb.control(undefined, Validators.required),
      birthNation: this.fb.control(undefined, Validators.required)
    });
  }

  ngOnInit(): void {

    this.generateModalFormGroup();
    this.generateFiscalCodeFormGroup();

    this.fiscalCodeFormGroup.valueChanges.subscribe(r => {
      this.modalError = null;
    });
    this.configureCityAutocomplete(this.fiscalCodeFormGroup);
    this.fiscalCodeFormGroup.get('birthNation').valueChanges.subscribe(value => {
      /*  if (value && value !== DEFAULT_COUNTRY) {
         this.fiscalCodeFormGroup.get('birthCity').disable();
         this.fiscalCodeFormGroup.get('birthDistrict').disable();
       } else {
         this.fiscalCodeFormGroup.get('birthCity').enable();
         this.fiscalCodeFormGroup.get('birthDistrict').enable();
       }
        */
      this.fiscalCodeFormGroup.get('birthDistrict').reset();
      this.fiscalCodeFormGroup.get('birthCity').reset();
      this.cityAutocompleteInputOptions = [];
      const birthNationValue = value;
      if (birthNationValue && birthNationValue !== DEFAULT_COUNTRY) {
        this.fiscalCodeFormGroup.patchValue({
          birthCity: DEFAULT_CITY,
          birthDistrict: DEFAULT_DISTRICT
        });
      }
      this.fiscalCodeFormGroup.markAsPristine();
    });


    this.logModalActivity();

    this.modalFormGroup
      .get('mgmCode')
      .valueChanges
      .subscribe(r => {
        this.modalError = null;
      });

    this.modalFormGroup
      .get('couponCode')
      .valueChanges
      .subscribe(r => {
        this.modalError = null;
      });

    this.subscriptions.add(
      this.actions$.pipe(
        ofType(ModalActions.openModal),
        filter(action => action.modalType === ModalType.MgmInfo)
      ).subscribe(action => {
        this.activeModal = {
          closeButton: false,
          additionalClasses: [ 'sb-modal', 'sb-modal--no-close' ],
          close: () => {
            this.closeModal();
          }
        } as Modal;
        this.modalFormGroup.patchValue({
          mgmCode: ''
        });
        this.activeModal = {
          ...this.activeModal,
          type: action.modalType,
          content: action.params.content || '',
          resolve: () => {
            const ERROR_ALREADY_USED = 'ALREADY_USED';
            const ERROR_GENERIC = 'GENERIC';
            const ERROR_CODE_OWNER = 'CODE_OWNER';
            const ERROR_CODE_TOO_MANY = 'BMGM-02';
            const CODE_ERRORS = [ ERROR_ALREADY_USED, ERROR_CODE_OWNER, ERROR_GENERIC, ERROR_CODE_TOO_MANY ];
            const code = this.modalFormGroup.get('mgmCode').value;
            if (code) {
              this.modalError = null;
              this.execService
                .setMgmInfo(code.toUpperCase().trim())
                .subscribe(result => {
                  if (result.code && !CODE_ERRORS.includes(result.code)) {
                    this.appStoreFacadeService.entity.storeEntityValue(
                      DynamicEntityKey.MgmInfo, result
                    );
                    this.analyticsService.sendCustomEvent({
                      custom_category: 'codice amico',
                      custom_action: 'inserimento ok',
                      custom_label: 'click su invia'
                    });
                    this.closeModal();
                  } else {
                    this.analyticsService.sendCustomEvent({
                      custom_category: 'codice amico',
                      custom_action: 'errore',
                      custom_label: 'click su invia'
                    });
                    /* OLD CODE switch (result.code) {
                      default:

                        break;
                    } */
                    this.modalError = result.code || ERROR_GENERIC;
                  }
                });
            } else {
              this.modalError = ERROR_GENERIC;
            }
          }
        };
      })
    );

    this.managePreviousStepModal();
    this.manageConfirmationModal();
    this.manageGenericErrorModal();
    this.manageICCIDErrorModal();
    this.managePriceChangeModal();
    this.manageVRErrorModal();
    this.manageFlowErrorModal();
    this.managePromoInfoModal();
    this.manageVRTutorialModal();
    this.manageH3GErrorModal();
    this.manageCouponModal();
    this.manageGenericModal();
    this.manageOtpModal();
    this.manageTaxCodeModal();
    this.manageEsimCompatibleDevicesModal();
  }

  private logModalActivity() {
    this.subscriptions.add(
      this.actions$.subscribe(action => {
        console.warn('EXECUTING MODAL ACTION: ', action);
      })
    );
  }

  get isBirthCityDisabled() {
    const birthNationField = this.fiscalCodeFormGroup && this.fiscalCodeFormGroup.get('birthNation');
    return !(birthNationField && (!birthNationField.value || birthNationField.value === DEFAULT_COUNTRY));
  }

  public fillDistrictFromCityModel(city: City) {
    const districtFormControl = this.fiscalCodeFormGroup.get('district');
    if (!districtFormControl) {
      return;
    }
    districtFormControl.patchValue(city.district);
  }

  private searchCitiesOnUserInput(cityValue: string) {
    this.cityAutocompleteInputOptions = [];
    this.cityAutocompleteInputLoading = true;

    this.execService.getCitiesFromCityName(cityValue)
      .pipe(
        first(Boolean),
        tap(() => (this.cityAutocompleteInputLoading = false))
      )
      .subscribe((citiesFound: City[]) => {
        this.cityAutocompleteInputOptions = citiesFound;
      });
  }


  private configureCityAutocomplete(taxcodeFormGroup: FormGroup): void {
    // Listen to user-input changes
    this.searchCitiesOnUserInputSubject
      .pipe(
        debounceTime(ModalsComponent.USER_INPUT_DEBOUNCE_INTERVAL),
        filter((cityValue: string) => ((cityValue || '').length >= FormConstants.MINIMUM_CITY_LENGTH)),
        distinctUntilChanged(),
      )
      .subscribe((cityValue: string) => {
        this.searchCitiesOnUserInput(cityValue);
      });

    // Listen to control value changes (form value patches)
    const cityFormControl = taxcodeFormGroup.get('birthCity');
    const districtFormControl = taxcodeFormGroup.get('birthDistrict');
    if (!cityFormControl || !districtFormControl) {
      return;
    }

    this.subscriptions.add(
      cityFormControl.valueChanges
        .pipe(
          filter(Boolean),
          filter((cityValue: string) => ((cityValue || '').length >= FormConstants.MINIMUM_CITY_LENGTH)),
        )
        .subscribe((cityValue) => {
          const foundCityIndex = this.cityAutocompleteInputOptions.findIndex((city: City) => city.city === cityValue);

          if (foundCityIndex === -1) {
            const cityFormControlValue = {
              fullName: cityValue,
              city: cityValue
            } as City;

            this.cityAutocompleteInputOptions = [ cityFormControlValue ];
          } else {
            districtFormControl.patchValue(this.cityAutocompleteInputOptions[foundCityIndex].district);
          }
        })
    );
  }

  private manageConfirmationModal() {
    this.subscriptions.add(
      this.actions$.pipe(
        ofType(ModalActions.openModal),
        filter(action => action.modalType === ModalType.Confirmation)
      ).subscribe(action => {
        const additionalClasses = [ 'confirmation-modal', 'sb-modal' ];
        if (action.params.additionalClasses) {
          additionalClasses.push(action.params.additionalClasses);
        }
        this.activeModal = {
          closeButton: !!action.params.closeButton,
          additionalClasses,
          close: () => {
            this.closeModal();
          }
        } as Modal;
        this.activeModal = {
          ...this.activeModal,
          type: ModalType.Confirmation,
          title: action.params.title,
          content: action.params.message,
          buttonContainerClass: action.params.buttonContainerClass,
          buttonsPosition: action.params.buttonsPosition,
          options: action.params.options,
          hideCancelButton: action.params.hideCancelButton,
          resolve: (value) => {
            this.closeModal();
            action.params.resolve(value);
          }
        };
      })
    );
  }

  private managePreviousStepModal() {
    this.subscriptions.add(
      this.actions$.pipe(
        ofType(ModalActions.openPreviousStepModal)
      ).subscribe(action => {
        this.activeModal = {
          closeButton: true,
          additionalClasses: [ 'confirmation-modal', 'sb-modal' ],
          close: () => {
            this.closeModal();
          }
        } as Modal;
        this.activeModal = {
          ...this.activeModal,
          type: ModalType.PreviousStep,
          content: action.message,
          resolve: (value) => {
            this.closeModal();
            action.resolve(value);
          }
        };
      })
    );
  }

  private manageGenericErrorModal() {
    this.subscriptions.add(
      this.actions$.pipe(
        ofType(ModalActions.openGenericErrorModal)
      ).subscribe(action => {
        this.activeModal = {
          closeButton: true,
          additionalClasses: [ 'error-modal', 'sb-modal' ],
          close: () => {
            this.closeModal();
          }
        } as Modal;
        this.activeModal = {
          ...this.activeModal,
          type: ModalType.Error,
          content: action.payload,
          title: typeof action.title === 'undefined' ? 'Attenzione!' : action.title,
          additionalClasses: action.icon ? [ action.icon ] : []
        };
      })
    );
  }

  private manageH3GErrorModal() {
    this.subscriptions.add(
      this.actions$.pipe(
        ofType(ModalActions.openH3GErrorModal)
      ).subscribe(action => {
        this.activeModal = {
          closeButton: true,
          additionalClasses: [ 'iccid-error-modal', 'sb-modal' ],
          close: () => {
            this.closeModal();
          }
        } as Modal;
        this.activeModal = {
          ...this.activeModal,
          type: ModalType.H3GError,
          resolve: () => {
            this.closeModal();
            window.location.href = 'https://www.verymobile.it';
          }
        };
      })
    );
  }

  private manageICCIDErrorModal() {
    this.subscriptions.add(
      this.actions$.pipe(
        ofType(ModalActions.openIccidErrorModal)
      ).subscribe(action => {
        this.activeModal = {
          closeButton: true,
          additionalClasses: [ 'iccid-error-modal', 'sb-modal' ],
          close: () => {
            this.closeModal();
          }
        } as Modal;
        this.activeModal = {
          ...this.activeModal,
          type: ModalType.IccidError
        };
      })
    );
  }

  private managePriceChangeModal() {
    this.subscriptions.add(
      this.actions$.pipe(
        ofType(ModalActions.openOperatorPriceChangeModal)
      ).subscribe(action => {
        this.activeModal = {
          closeButton: true,
          additionalClasses: [ 'operator-price-modal', 'sb-modal' ],
          close: () => {
            this.closeModal();
            action.resolve(false);
          }
        } as Modal;
        this.activeModal = {
          ...this.activeModal,
          type: ModalType.OperatorPriceChange,
          options: action.options,
          resolve: (value) => {
            this.closeModal();
            action.resolve(value);
          }
        };
      })
    );
  }

  private manageVRErrorModal() {
    this.subscriptions.add(
      this.actions$.pipe(
        ofType(ModalActions.openVrErrorModal)
      ).subscribe(action => {
        this.activeModal = {
          closeButton: true,
          additionalClasses: ['vr-error-modal', 'sb-modal'],
          close: () => {
            this.closeModal();
          }
        } as Modal;
        this.activeModal = {
          ...this.activeModal,
          type: ModalType.VRError,
          content: action.message
        };
      })
    );
  }

  private manageVRTutorialModal() {
    this.subscriptions.add(
      this.actions$.pipe(
        ofType(ModalActions.openVrTutorialModal)
      ).subscribe(action => {
        this.activeModal = {
          closeButton: true,
          additionalClasses: [ 'vr-tutorial', 'sb-modal' ],
          close: () => {
            this.closeModal();
          }
        } as Modal;
        this.activeModal = {
          ...this.activeModal,
          type: ModalType.VRTutorial
        };
      })
    );
  }

  private manageFlowErrorModal() {
    this.subscriptions.add(
      this.actions$.pipe(
        ofType(ModalActions.openModal),
        filter(action => action.modalType === ModalType.FlowError)
      ).subscribe(action => {
        this.activeModal = {
          closeButton: true,
          additionalClasses: ['sb-modal--square-padding', 'sb-modal'],
          close: () => {
            combineLatest([
              this.appStoreFacadeService.entity.offer$,
              this.appStoreFacadeService.entity.simType$
            ]).subscribe(([ offer, simTypeEntity ]) => {
              const productId = offer.productId || offer.ropz;
              const simType = simTypeEntity.value.type;
              console.warn('TRUE FLOW ERROR MODAL');
              this.appStoreFacadeService.spinner.updateSpinnerStatus(true);
              this.closeModal();
              this.execService.resetFlow(productId, simType);
            });
          }
        } as Modal;
        this.activeModal = {
          ...this.activeModal,
          type: ModalType.FlowError
        };
      })
    );
  }

  private managePromoInfoModal() {
    this.subscriptions.add(
      this.actions$.pipe(
        ofType(ModalActions.openModal),
        filter(action => action.modalType === ModalType.PromoInfo)
      ).subscribe(action => {
        this.activeModal = {
          closeButton: false,
          type: ModalType.PromoInfo,
          content: action.params && action.params.message,
          additionalClasses: ['sb-modal--square-padding', 'sb-modal'],
          close: () => {
            combineLatest([
              this.appStoreFacadeService.entity.offerPromoInfo$
            ]).subscribe(([ offerPromoInfo ]) => {
              this.closeModal();
            });
          }
        } as Modal;
      })
    );
  }

  private manageCouponModal() {
    this.subscriptions.add(
      this.actions$.pipe(
        ofType(ModalActions.openModal),
        filter(action => action.modalType === ModalType.Coupon)
      ).subscribe(action => {
        this.activeModal = {
          closeButton: true,
          additionalClasses: [ 'sb-modal', 'sb-modal--no-close'],
          close: () => {
            this.closeModal();
          }
        } as Modal;
        this.modalFormGroup.patchValue({
          couponCode: ''
        });
        this.activeModal = {
          ...this.activeModal,
          type: action.modalType,
          resolve: () => {
            const ERROR_ALREADY_USED = 'ALREADY_USED';
            const ERROR_GENERIC = 'GENERIC';
            const ERROR_CODE_OWNER = 'CODE_OWNER';
            const ERROR_CODE_TOO_MANY = 'BMGM-02';
            const CODE_ERRORS = [ ERROR_ALREADY_USED, ERROR_CODE_OWNER, ERROR_GENERIC, ERROR_CODE_TOO_MANY ];
            const code = this.modalFormGroup.get('couponCode').value;
            if (code) {
              this.modalError = null;
              this.execService
                .checkCouponCode(code.toUpperCase())
                .subscribe(result => {
                  if (result.code && !CODE_ERRORS.includes(result.code)) {
                    this.appStoreFacadeService.entity.storeEntityValue(
                      DynamicEntityKey.CouponInfo, result
                    );
                    // Retrieve offer promo info to update basket
                    this.basketService
                      .getEntitiesData([ StaticEntityKey.OfferPromoInfo, StaticEntityKey.Offer, StaticEntityKey.Cart ])
                      .subscribe(entities => {
                        this.appStoreFacadeService.entity.storeEntityValue(
                          StaticEntityKey.Offer, entities[StaticEntityKey.Offer]
                        );
                        this.appStoreFacadeService.entity.storeEntityValue(
                          StaticEntityKey.Cart, entities[StaticEntityKey.Cart]
                        );
                        this.appStoreFacadeService.entity.storeEntityValue(
                          StaticEntityKey.OfferPromoInfo, entities[StaticEntityKey.OfferPromoInfo]
                        );
                      });

                    this.analyticsService.sendCustomEvent({
                      custom_category: 'coupon',
                      custom_action: 'inserimento ok',
                      custom_label: 'click su invia'
                    });
                    this.closeModal();
                  } else {
                    this.analyticsService.sendCustomEvent({
                      custom_category: 'coupon',
                      custom_action: 'errore',
                      custom_label: 'click su invia'
                    });
                    /* OLD CODE switch (result.code) {
                      default:
                        break;
                    } */
                    this.modalError = result.code || ERROR_GENERIC;
                  }
                });
            } else {
              this.modalError = ERROR_GENERIC;
            }
          }
        };
      })
    );
  }

  private manageGenericModal() {
    this.subscriptions.add(
      this.actions$.pipe(
        ofType(ModalActions.openModal),
        filter(action => action.modalType === ModalType.Generic)
      ).subscribe(action => {
        this.activeModal = {
          closeButton: true,
          additionalClasses: [ 'generic-modal', 'sb-modal' ],
          title: action.params.title,
          content: action.params.message,
          close: () => {
            this.closeModal();
          }
        } as Modal;
        this.activeModal = {
          ...this.activeModal,
          type: ModalType.Generic,
          content: action.params.message,
          resolve: (value) => {
            this.closeModal();
            action.params.resolve(value);
          }
        };
      })
    );
  }

  get isMGMButtonDisabled() {
    const mgmField = this.modalFormGroup && this.modalFormGroup.get('mgmCode');
    return !!(mgmField.invalid);
  }

  get isCOUPONButtonDisabled() {
    // OLD CODE const couponField = this.modalFormGroup && this.modalFormGroup.get('couponCode');
    return false; // OLD CODE !(couponField && couponField.value && couponField.value.length === 8);
  }

  private manageOtpModal() {
    this.subscriptions.add(
      this.modalFormGroup.get('otpCode')
        .valueChanges
        .subscribe(resp => {
          this.modalError = null;
        })
    );
    this.subscriptions.add(
      this.actions$.pipe(
        ofType(ModalActions.openModal),
        filter(action => action.modalType === ModalType.OtpModal)
      ).subscribe(action => {
        if (action.params.options.error) {
          this.modalError = action.params.options.error;
        }
        this.activeModal = {
          closeButton: true,
          additionalClasses: [ 'sb-modal', 'sb-modal--with-close', 'sb-modal--otp' ],
          close: () => {
            this.modalError = null;
            this.closeModal();
            this.modalFormGroup.get('otpCode').reset();
            action.params.options.reset();
          }
        } as Modal;
        this.activeModal = {
          ...this.activeModal,
          type: ModalType.OtpModal,
          content: action.params.message,
          title: action.params.title,
          options: action.params.options,
          resendOtp: () => {
            this.modalFormGroup.get('otpCode').reset();
            this.modalError = null;
            this.closeModal();
            action.params.options.sendOtp();
          },
          resolve: (value) => {
            const code = this.modalFormGroup.get('otpCode').value;
            if (code) {
              // We cannot use the verifyOtp because it is idempotent
              this.modalError = null;
              this.closeModal();
              this.appStoreFacadeService.entity.storeEntityValue(
                DynamicEntityKey.OtpInfo, { code }
              );
              action.params.resolve(true);
              this.modalFormGroup.get('otpCode').reset();
            }
          }
        };
      })
    );
  }

  get isOtpButtonDisabled() {
    const otpField = this.modalFormGroup && this.modalFormGroup.get('otpCode');
    return !(otpField && otpField.value && otpField.value.length === MAXIMUM_OTP_LENGTH);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  inferOtpErrorText(errorCode) {
    return `ENTITY.ACTIVATION-MNP-INFO.OTP_ERROR.${errorCode}`;
  }

  private closeModal() {
    console.warn('Im closing the current modal');
    this.activeModal = null;
  }

  private manageTaxCodeModal() {
    this.subscriptions.add(
      this.actions$
        .pipe(
          ofType(ModalActions.openModal),
          filter(action => action.modalType === ModalType.TaxCodeCalculation)
        ).subscribe((action) => {
        this.modalError = null;
        this.fiscalCodeFormGroup.patchValue({
          birthNation: DEFAULT_COUNTRY
        });
        if (action.params.customer) {
          this.fiscalCodeFormGroup.patchValue(action.params.customer);
        }
        this.activeModal = {
          closeButton: true,
          additionalClasses: ['tax-code-modal', 'sb-modal'],
          resolve: () => {
            const birthNationValue = this.fiscalCodeFormGroup.get('birthNation').value;
            if (birthNationValue && birthNationValue !== DEFAULT_COUNTRY) {
              this.fiscalCodeFormGroup.patchValue({
                birthCity: DEFAULT_CITY,
                birthDistrict: DEFAULT_DISTRICT
              });
            }
            // ARES-4678: OB/VERY [FE] - fix bug sulla data nel calcolo codice fiscale per Very e OB
            if (this.fiscalCodeFormGroup.valid) {
              const formData = this.fiscalCodeFormGroup.value;
              const birthDateParts = formData.birthDate.split('/');
              if (birthDateParts[2] && birthDateParts[2].length > 4) {
                birthDateParts[2] = birthDateParts[2].substr(0, 4);
              }
              formData.birthDate = birthDateParts.join('/');

              this.execService.encodeTaxCode(formData)
                .subscribe(resp => {
                  if (!resp || resp.error) {
                    this.modalError = true;
                    return;
                  }
                  if (resp && resp.taxCode) {
                    action.params.onSuccess({
                      firstName: this.fiscalCodeFormGroup.get('firstName').value,
                      lastName: this.fiscalCodeFormGroup.get('lastName').value,
                      taxCode: resp.taxCode
                    });
                    this.activeModal.close();
                  }
                });
            }
          },
          close: () => {
            this.fiscalCodeFormGroup.reset();
            this.activeModal = null;
            this.modalError = null;
          },
        } as Modal;
        this.activeModal = {
          ...this.activeModal,
          type: ModalType.TaxCodeCalculation,
        };
        if (this.field) {
          setTimeout(() => {this.field.first.inputElement.nativeElement.focus(); } , );
        }
      })
    );
  }

  private manageEsimCompatibleDevicesModal() {
    this.subscriptions.add(
      this.actions$.pipe(
        ofType(ModalActions.openEsimCompatibleDevicesModal)
      ).subscribe(action => {
        this.activeModal = {
          closeButton: true,
          additionalClasses: [ 'esim-compatible', 'sb-modal' ],
          close: () => {
            this.closeModal();
          }
        } as Modal;
        this.activeModal = {
          ...this.activeModal,
          type: ModalType.EsimCompatibleDevices
        };
      })
    );
  }

}
