<div class="cart-partial-price-and-delivery-info" *ngFor="let item of cartItems">
  <ng-container *ngIf="item.type !== 'addon'">
    <div class="cart-partial-price-and-delivery-info__container">
      <div class="cart-partial-price-and-delivery-info__title">
       <div *ngIf="item.type === 'simActivation' && (simType === SimTypeEnumList.eSim || simType === SimTypeEnumList.IdleSim || simType === SimTypeEnumList.Standard)" class="heading-subtitle">
          <img src="assets/images/icon-cart-activation-free.svg" class="cart-icon" alt="P">
          {{inferSimActivationLabel() | translate}}

          <div class="cart-partial-price-and-delivery-info__price-discount strikediag" *ngIf="item.price.previous">{{ item.price.previous | inferExclusiveDiscount:special | currency:item.price.currency | inferCurrencyLabel}}</div>
        </div>
        <!-- <div *ngIf="item.type === 'simActivation' && item.type !== 'addon'" class="heading-title heading-subtitle flex 11">
          <img src="assets/images/icon-cart-activation-free.svg" class="cart-icon" alt="P">
          {{ item.type | inferCartPriceLabels | translate }}
          <div class="cart-partial-price-and-delivery-info__price-discount strikediag 14" *ngIf="item.price.previous">
              {{ item.price.previous | inferExclusiveDiscount:special | currency:item.price.currency | inferCurrencyLabel }}
          </div>
        </div> -->
          <div *ngIf="item.type === 'shipping' && item.type !== 'addon'" class="heading-title heading-subtitle flex ">
            <img src="assets/images/icon_cart_delivery.svg" class="cart-icon" alt="P">
            {{ item.type | inferCartPriceLabels | translate }}
            <div class="cart-partial-price-and-delivery-info__price-discount strikediag" *ngIf="item.price.previous">
                {{ item.price.previous | inferExclusiveDiscount:special | currency:item.price.currency | inferCurrencyLabel }}
            </div>
        </div>
        <div *ngIf="item.type !== 'simActivation' && item.type !== 'shipping' && item.type !== 'addon'" class="heading-title">
            {{ item.type | inferCartPriceLabels | translate }}
            <div class="cart-partial-price-and-delivery-info__price-discount strikediag" *ngIf="item.price.previous">
                {{ item.price.previous | inferExclusiveDiscount:special | currency:item.price.currency | inferCurrencyLabel }}
            </div>
        </div>
       <!--  <span *ngIf="item.type === 'simActivation' && (simType === SimTypeEnumList.eSim || simType === SimTypeEnumList.IdleSim)" class="heading-subtitle 32">
          <img src="assets/images/icon-cart-activation-free.svg" class="cart-icon" alt="P">
          {{inferSimActivationLabel() | translate}}
        </span>
        <span *ngIf="(item.type !== 'simActivation' && item.type !== 'addon') || (simType !== SimTypeEnumList.eSim && simType != SimTypeEnumList.IdleSim)" class="heading-title 36">
          {{item.type | inferCartPriceLabels | translate}}
        </span>
        <div class="cart-partial-price-and-delivery-info__price-discount strikediag 39" *ngIf="item.price.previous">{{ item.price.previous | inferExclusiveDiscount:special | currency:item.price.currency | inferCurrencyLabel}}</div> -->
      </div>
      <div class="cart-partial-price-and-delivery-info__frequency">{{item.price.frequency | inferFrequencyLabel:special:item.type | translate}}</div>
    </div>
    <div class=" cart-partial-price-and-delivery-info__container price" > <!-- *ngIf="shouldShowOptions" -->
      <div class="cart-partial-price-and-delivery-info__price heading-subtitle">
        {{ item.price.value | currency:item.price.currency | inferCurrencyLabel | inferFreeLabel:item.price.value:inferIsPlural(item.type) | translate}}
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="item.type === 'addon' && item.title">
    <div class="flex-column width-100">
      <span class="separator"></span>
      <div class="cart-partial-price-and-delivery-info">
        <div class="cart-partial-price-and-delivery-info__container">
          <div class="cart-partial-price-and-delivery-info__title">
            <span class="heading-title">
              {{item.title}}
            </span>
          </div>
          <div class="cart-partial-price-and-delivery-info__frequency addon heading-subtext">{{item?.price?.repetitions | inferRepetitionsLabel | translate}}</div>
        </div>
        <div class="cart-partial-price-and-delivery-info__container price" >
          <div class="cart-partial-price-and-delivery-info__price-discount strikethrough" *ngIf="item?.price?.previous">{{item.price.previous ? item.price.previous : '0.00'}}{{item?.price?.currency | inferCurrencyLabel}}</div>
          <div class="cart-partial-price-and-delivery-info__price">
            {{ item.price.value | currency:item.price.currency | inferCurrencyLabel | inferFreeLabel:item?.price?.value:inferIsPlural(item.type) | translate}}
          </div>
        </div>
      </div>
      <div class="cart-partial-price-and-delivery-info mb-0">
        <div class="flex">
          <div class="img-container">
            <img *ngIf="!item?.activationPrice?.value" src="assets/images/icon-cart-activation-free.svg" class="cart-icon" alt="P">
          </div>
          <div class="cart-partial-price-and-delivery-info__title flex-column">
            <span class="heading-subtitle">{{'ENTITY.OFFER-EXTRAS.ADDONS.ACTIVATION' | translate}}</span>
            <div class="cart-partial-price-and-delivery-info__frequency heading-subtitletext">{{'ENTITY.OFFER-EXTRAS.ADDONS.ACTIVATION.UNA-TANTUM' | translate}}</div>
          </div>
        </div>
        <div class="cart-partial-price-and-delivery-info__container">
          <div class="cart-partial-price-and-delivery-info__price heading-subtitle">
            <span *ngIf="item?.activationPrice?.value; else freeActivation">{{ item?.activationPrice?.value | currency: item?.activationPrice?.currency | inferCurrencyLabel}}</span>
            <ng-template #freeActivation>
              <span>{{'ENTITY.OFFER-EXTRAS.ADDONS.FREE_ACTIVATION' | translate}}</span>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<span class="separator"></span>

<div class="cart-partial-price-and-delivery-info">
  <div class=" cart-partial-price-and-delivery-info__container flexy">
    <div class="cart-partial-price-and-delivery-info__totals">
      {{total.type | inferCartPriceLabels | translate}}
      <div class="cart-partial-price-and-delivery-info__price-discount strikediag" *ngIf="total.price.previous">
        {{ total.price.previous | inferExclusiveDiscount:special | currency:total.price.currency | inferCurrencyLabel | inferFreeLabel:total.price.value | translate}}
      </div>
    </div>
    <div class="cart-partial-price-and-delivery-info__frequency" *ngIf="inferShouldShowFrequency()">
      {{ total.price.frequency | inferFrequencyLabel:special:null | translate}}
    </div>
  </div>
  <div class=" cart-partial-price-and-delivery-info__container">
    <div class="cart-partial-price-and-delivery-info__totals">
      {{ total.price.value | currency:total.price.currency | inferCurrencyLabel | inferFreeLabel:total.price.value | translate}}
    </div>
  </div>
</div>



