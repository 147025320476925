import { createAction, props } from '@ngrx/store';
import { ModalType } from '../../../../app-shared/models/modal.interface';

export enum ModalActionsTypes {
  OpenPreviousStepModal = '[OpenModalAction] OpenPreviousStepModal',
  OpenRedirectModal = '[OpenModalAction] OpenRedirectModal',
  OpenIccidErrorModal = '[OpenModalAction] OpenIccidErrorModal',
  OpenH3GErrorModal = '[OpenModalAction] OpenH3GErrorModal',
  OpenGenericErrorModal = '[OpenModalAction] OpenGenericErrorModal',
  OperatorPriceChangeModal = '[OpenModalAction] OperatorPriceChangeModal',
  OpenVRErrorModal = '[OpenModalAction] OpenVRErrorModal',
  OpenVRTutorialModal = '[OpenModalAction] OpenVRTutorialModal',
  OpenModal = '[OpenModalAction] OpenModal',
  OpenEsimCompatibleDevicesModal = '[OpenModalAction] OpenEsimCompatibleDevicesModal'
}

export const openGenericErrorModal = createAction(
  ModalActionsTypes.OpenGenericErrorModal,
  props<{ payload: string, icon?: string, title?: string }>()
);

export const openPreviousStepModal = createAction(
  ModalActionsTypes.OpenPreviousStepModal,
  props<{ message: string, resolve: any }>()
);

export const openModal = createAction(
  ModalActionsTypes.OpenModal,
  props<{ modalType: ModalType, params?: any }>()
);

export const openRedirectModal = createAction(
  ModalActionsTypes.OpenRedirectModal,
  props<{ message: string, url: string }>()
);

export const openIccidErrorModal = createAction(
  ModalActionsTypes.OpenIccidErrorModal,
  props<{}>()
);

export const openH3GErrorModal = createAction(
  ModalActionsTypes.OpenH3GErrorModal,
  props<{}>()
);

export const openOperatorPriceChangeModal = createAction(
    ModalActionsTypes.OperatorPriceChangeModal,
    props<{ options: any, resolve: any }>()
);

export const openVrErrorModal = createAction(
  ModalActionsTypes.OpenVRErrorModal,
  props<{ message: string }>()
);

export const openVrTutorialModal = createAction(
  ModalActionsTypes.OpenVRTutorialModal,
  props<{}>()
);

export const openEsimCompatibleDevicesModal = createAction(
  ModalActionsTypes.OpenEsimCompatibleDevicesModal,
  props<{}>()
);

export type ModalActionsUnion = ReturnType<
  | typeof openPreviousStepModal
  | typeof openRedirectModal
  | typeof openIccidErrorModal
  | typeof openGenericErrorModal
  | typeof openOperatorPriceChangeModal
  | typeof openVrErrorModal
  | typeof openVrTutorialModal
  | typeof openModal
  | typeof openEsimCompatibleDevicesModal
  >;
