import { Pipe, PipeTransform } from '@angular/core';
import { CartAnalysis, CartAnalysisActivationMethodsEnum } from 'models';

@Pipe({
  name: 'inferOperatorsCartLinkMessage'
})
export class InferOperatorsCartLinkMessagePipe implements PipeTransform {

  transform(cartAnalysis: CartAnalysis, operators: string[]): any {
    let message = 'ENTITY.CART.OTHER_VIRTUAL_OPERATORS';
    if (!cartAnalysis) { return ''; }
    if (cartAnalysis.activationMechanism === CartAnalysisActivationMethodsEnum.New) {
      message = '';
    } else if (cartAnalysis.activationMechanism === CartAnalysisActivationMethodsEnum.Mnp) {
      // OLD STUFF if (Object.keys(cartAnalysis.operatorsByPrice).length >= 1) {
      message = '';
    }
    return message;
  }

}
