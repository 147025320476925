export enum ModalType {
  Confirmation = 'Confirmation',
  Coupon = 'Coupon',
  Error = 'Error',
  FlowError = 'FlowError',
  Generic = 'Generic',
  H3GError = 'H3GError',
  IccidError = 'IccidError',
  MgmInfo = 'MgmInfo',
  Redirect = 'Redirect',
  OperatorPriceChange = 'OperatorPriceChange',
  PreviousStep = 'PreviousStep',
  PromoInfo = 'PromoInfo',
  TaxCodeCalculation = 'TaxCodeCalculation',
  VRError = 'VRError',
  VSCConfirm = 'VSCConfirm',
  VRTutorial = 'VRTutorial',
  OtpModal = 'OtpModal',
  EsimCompatibleDevices = 'EsimCompatibleDevices',
}

export enum ModalButtonPositionEnum {
  HORIZONTAL = 'HORIZONTAL',
  HORIZONTAL_REVERSE = 'HORIZONTAL_REVERSE',
  VERTICAL = 'VERTICAL',
  VERTICAL_REVERSE = 'VERTICAL_REVERSE',
}

export interface Modal {
  type: ModalType;
  content: string;

  acceptLabel?: string;
  rejectLabel?: string;

  closeButton?: boolean;
  additionalClasses?: string[];
  close?: any;
  title?: any;
  resolve?: any;
  options?: any;

  resendOtp?: any;
  buttonsPosition?: ModalButtonPositionEnum;
  buttonContainerClass?: string;
  hideCancelButton?: boolean;
}
